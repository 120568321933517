import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import AlertDialogLoginScreen from '../../components/AlertDialogLoginScreen';
import Register from '../../components/Register';

const styles = ({ breakpoints }: any) => createStyles({
    container: {
        height: 'calc(100vh)',
        display: 'flex',
        flexDirection: 'column',
        [breakpoints.down('sm')]: {
            overflowY: 'auto',
        }
    },
    imageBackground: {
        height: 'calc(100vh)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundSize: 'cover',
        backgroundImage: 'url(servicios-en-linea-bg.jpg)',
        [breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    topRight: {
        position: "absolute",
        top: 30,
        right: 30,
        zIndex: 1,
    },
    welcomeContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative"
    },
    welcome: {
        padding: "30px 40px",
        width: "60%"
    },
    logosContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "70px"
    },
    benefits: {
        paddingLeft: "30px",
        maxWidth: "75%"
    },
    logos: {
        maxWidth: "80%",
        zIndex: 2,
        position: "relative",
    },
    imageServices: {
        padding: "0px 40px",
        width: "90%"
    }
});

interface RegisterScreenProps extends WithStyles<typeof styles> {

}

export const RegisterScreen = ({ classes }: RegisterScreenProps) => {

    return (
        <div className={classes.container}>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3} style={{ backgroundColor: '#CECECE' }}>
                    <Register />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={9} xl={9} >
                    <div className={classes.imageBackground}>
                        <img src="../mega-logotipo.svg" alt="" className={classes.topRight} />
                        <div className={classes.welcomeContainer}>
                            <img src="../bienvenido-sel-mega.svg" alt="" className={classes.welcome} />
                            <img src="../logotipos-sel.svg" alt="" className={classes.imageServices} />
                        </div>
                        <div className={classes.logosContainer}>
                            <div>
                                <img src="../sel-informacion.svg" alt="" className={classes.benefits} />
                            </div>
                            <div>
                                <img src="../mega-en-linea.svg" alt="" className={classes.logos} />
                            </div>
                        </div>
                    </div>
                </Grid>
                <AlertDialogLoginScreen />
            </Grid>
        </div >
    )
}

export default withStyles(styles)(RegisterScreen);
