import { combineReducers } from 'redux';
// import { resettableReducer } from 'reduxsauce';

import reducers from 'megacable-mobile-library';
import currentContract from './currentContract';

// const resettable = resettableReducer('RESET');

export default combineReducers({
    ...reducers,
    currentContract
    // login: resettable(login),
    // loading,
    // alert,
    // androidVersion,
    // estadoCuenta: resettable(estadoCuenta),
    // mobile: resettable(mobile),
    // faq,
    // tickets: resettable(tickets),
    // pagosData: resettable(pagosData),
    // alias: resettable(alias),
    // cis,
    // financialInstitutions
})