import React, { useEffect } from 'react';
import { createStyles, withStyles } from '@material-ui/styles';

import Button from './Button';
import Input from './Input';
import Select from './Select';
import { AlertRedux, LoadingRedux } from 'megacable-mobile-library';
import awsmobile from '../aws-exports';
import * as api from 'megacable-mobile-api';
import _ from 'lodash';
import Spinner from '../components/Spinner';
import { useSelector, useDispatch } from 'react-redux';

const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;

const apiV2 = new api.V2Api({}, baseUrl);

const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const styles = ({ palette }: any) => createStyles({
    titleContainer: {
        width: '100%',
        height: '75px',
        padding: '20px',
        boxSizing: 'border-box',
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        backgroundColor: '#044c9b',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: '25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonsContainerRegistration: {
        display: 'inline-flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '8px'
    },
    chatContainer: {
        width: '100%',
        flex: 1,
        fontFamily: 'Sarabun',
        fontWeight: 'bold',
        backgroundColor: '#002A4F',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: '29px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    chatIcon: {
        verticalAlign: '-33%',
        width: '55px',
        height: '45px',
        marginRight: '15px'
    },
});

interface RegisterProps {
    classes: any;
}

const selectOptions = [
    { label: "Celular", value: "Celular" },
    { label: "Correo Electronico", value: "Email" },
]

export const Register = ({ classes }: RegisterProps) => {

    const [selectedValue, setSelectedValue] = React.useState<"Celular" | "Email">("Celular");
    const [suscriptor, setSuscriptor] = React.useState("");
    const [suscriptorName, setSuscriptorName] = React.useState("");
    const [extNumber, setExtNumber] = React.useState("");
    const [cellphone, setCellphone] = React.useState("");
    const [cellphone2, setCellphone2] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [email2, setEmail2] = React.useState("");
    const loading = useSelector((state: any) => state.loading.showLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedValue) {
            setCellphone("");
            setCellphone2("");
            setEmail("");
            setEmail2("");
        }
    }, [selectedValue]);

    useEffect(() => {
        setSuscriptor("");
        setSuscriptorName("");
        setExtNumber("");
        setCellphone("");
        setCellphone2("");
        setEmail("");
        setEmail2("");
    }, []);

    const addNewSuscriptor = (suscriptorData: any) => {
        apiV2.postApiV2AddNewSuscriptor(suscriptorData)
            .then(response => {
                dispatch(LoadingRedux.Creators.hideLoading());
                if (_.has(response, 'data.status') && _.has(response, 'data.message')) {
                    if (response.data.status === 'true') {
                        dispatch(AlertRedux.showAlert(response.data.message + " Se enviará tu contraseña por el tipo de registro seleccionado", "Solicitud de registro"))
                    } else {
                        dispatch(AlertRedux.showAlert(response.data.message, "Solicitud de registro"))
                    }
                }
            })
            .catch(error => {
                dispatch(LoadingRedux.Creators.hideLoading());
                error = _.get(error, 'response', error);
                dispatch(AlertRedux.showAlert("Los datos ingresados no coinciden, favor de validar tu información con el área de soporte a suscriptores.", "Solicitud de registro"))
            })
    }

    const alerDialog = (message: string, title: string) => {
        dispatch(AlertRedux.showAlert(message, title));
    }

    function register() {
        dispatch(LoadingRedux.Creators.showLoading());
        if (suscriptor && suscriptor.length === 10 && suscriptorName && extNumber && ((cellphone && cellphone2) || (email && email2))) {
            if ((cellphone && cellphone2 && cellphone === cellphone2) || (email && email2 && email === email2)) {
                let sucursalSucriptor = suscriptor.substr(0, 3);
                let suscriptorNumber = suscriptor.substr(3, suscriptor.length)
                let suscriptorData: any = {
                    sucursal: sucursalSucriptor,
                    suscriptor: suscriptorNumber,
                    nombre_completo: suscriptorName,
                    exterior: extNumber,
                    tipo_registro: selectedValue === "Celular" ? "celular" : "email",
                    registro: cellphone ? cellphone : email ? email : ''
                }
                if (suscriptorData.tipoRegistro === "email") {
                    if (regexEmail.test(email)) {
                        addNewSuscriptor(suscriptorData);
                    } else {
                        dispatch(LoadingRedux.Creators.hideLoading());
                        alerDialog("Por favor ingrese un correo electrónico válido.", "Solicitud de registro");
                    }
                } else {
                    addNewSuscriptor(suscriptorData);
                }
            } else {
                alerDialog("El número celular o correo electrónico no coincide.", "Solicitud de registro")
                dispatch(LoadingRedux.Creators.hideLoading());
            }
        } else {
            alerDialog("Hay campos obligatorios sin datos o con datos incompletos.", "Solicitud de registro");
            dispatch(LoadingRedux.Creators.hideLoading());
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={classes.titleContainer}>
                {"SOLICITUD DE REGISTRO"}
            </div>
            <div style={{ height: 'calc(100vh-75px)', display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '100%', padding: '20px', boxSizing: 'border-box', overflowY: 'auto' }}>
                    <Input description="Consulte el número en su estado de cuenta. A continuación, ingrese los 10 dígitos sin guión." title="Número de suscriptor*" placeholder="" value={suscriptor} onChange={(e: any) => setSuscriptor(e.target.value.replace(/[^0-9]/g, ''))} maxLength={10} />
                    <Input description="Ingrese el nombre del suscriptor tal como aparece en el estado de cuenta." title="Nombre del suscriptor*" placeholder="" onChange={(e: any) => setSuscriptorName(e.target.value)} />
                    <Input description="Ingrese el número exterior del domicilio" title="Número exterior del domicilio*" placeholder="" onChange={(e: any) => setExtNumber(e.target.value)} />
                    <Select selected={selectedValue} width={'290px'} onChange={setSelectedValue} options={selectOptions} description="Seleccione el método con el que desea ingresar a servicios en línea." title="Deseo ingresar al sitio a tráves de" />
                    {
                        selectedValue === "Celular" ?
                            <>
                                <Input title="Número celular*" type='tel' placeholder="" value={cellphone} onChange={(e: any) => setCellphone(e.target.value.replace(/[^0-9]/g, ''))} maxLength={10} />
                                <Input title="Confirmar número celular*" type='tel' placeholder="" value={cellphone2} onChange={(e: any) => setCellphone2(e.target.value.replace(/[^0-9]/g, ''))} maxLength={10} />
                            </>
                            :
                            null
                    }
                    {
                        selectedValue === "Email" ?
                            <>
                                <Input title="Correo electrónico*" type="email" placeholder="" value={email} onChange={(e: any) => setEmail(e.target.value)} />
                                <Input title="Confirmar correo electrónico*" type="email" placeholder="" value={email2} onChange={(e: any) => setEmail2(e.target.value)} />
                            </>
                            :
                            null
                    }
                    <div style={{ margin: "8px 0px" }}>
                        {loading && <Spinner />}
                    </div>
                    <div className={classes.buttonsContainerRegistration}>
                        <div style={{ marginRight: '10px' }}>
                            <Button type="secondary" text="Regresar" size="medium" onClick={() => window.location.href = 'https://serviciosenlineav2.megacable.com.mx/'} />
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            <Button type="primary" text="Registrarse" size="medium" onClick={() => register()} />
                        </div>
                    </div>
                </div>
                {/* <div className={classes.chatContainer}>
                                    <img src="../chatbox-dialog.png" className={classes.chatIcon} alt="" />CHAT EN LÍNEA
                                </div> */}
            </div>
        </div>
    )
}

export default withStyles(styles)(Register);
