import axios, { AxiosInstance } from 'axios';
import awsmobile from '../aws-exports';
// import store from '../store'
// import _, { extend } from "lodash";
import { DataEdoCta, Alias, DatosPago, PaymentRoot, ResponseProxy, sendEdoCtaSMSResponse, AndoidVersion, SimpleSms, SimpleSmsResponse } from './types'
const baseUrl = awsmobile.aws_cloud_logic_custom[0].endpoint;

interface AuthInfo {
    token?: string,
    idCliente?: string
}

interface AxiosInstances {
    apiV2Instance?: AxiosInstance,
    apiV1Instance?: AxiosInstance
    apiV2InstanceWOSecurity?: AxiosInstance,
}

export const authInfo: AuthInfo = {
    token: undefined,
    idCliente: undefined
}

const axiosInstances: AxiosInstances = {
    apiV1Instance: undefined,
    apiV2Instance: undefined,
    apiV2InstanceWOSecurity: axios.create({
        baseURL: `${baseUrl}/api/v2`
    })
}

export const setToken = (token: string) => {
    authInfo.token = token;
    const headers = { "Authorization": `Bearer ${authInfo.token}` }
    axiosInstances.apiV1Instance = axios.create({
        baseURL: `${baseUrl}`,
        headers
    })
    axiosInstances.apiV2Instance = axios.create({
        baseURL: `${baseUrl}/api/v2`,
        headers
    })
}


// export const generics = <T extends {lastQuery:Date}> (arg: T): Promise<T> => {
//     return new Promise<T>((resolve, reject) => {
//         axiosInstances.apiV2Instance?.get<T>(`getEdoCta/${idCliente}`)
//             .then(response => {
//                 response.data.lastQuery =
//                 resolve(response.data)
//             })
//             .catch(error => {reject(error)})
//     })
// }

export const getEdoCuenta = (idCliente: string): Promise<DataEdoCta> => {
    return new Promise<DataEdoCta>((resolve, reject) => {
        axiosInstances.apiV2Instance?.get<DataEdoCta>(`getEdoCta/${idCliente}`)
            .then(response => { resolve(response.data) })
            .catch(error => { reject(error) })
    })
}

export const getAlias = (idCliente: string): Promise<Alias> => {
    return new Promise<Alias>((resolve, reject) => {
        axiosInstances.apiV1Instance?.get(`alias/${idCliente}`)
            .then(response => { resolve(response.data) })
            .catch(error => { reject(error) })
    })
}

export const getDatosPago = (idCliente: string): Promise<DatosPago> => {
    return new Promise<DatosPago>((resolve, reject) => {
        axiosInstances.apiV2Instance?.get(`getDatosPago/${idCliente}`)
            .then(response => { resolve(response.data) })
            .catch(error => { reject(error) })
    })
}

export const makePayment = (paymentRoot: PaymentRoot): Promise<ResponseProxy> => {

    return new Promise<ResponseProxy>((resolve, reject) => {
        axiosInstances.apiV2Instance?.post(`makePayment`, paymentRoot)
            .then(response => { resolve(response.data) })
            .catch(error => { reject(error) })
    })
}

export const sendEdoCtaSMS = (idCliente: string, celular: string): Promise<sendEdoCtaSMSResponse> => {
    // sendSMSEdoCuenta?idCliente=0411060596&celular=3338204001
    const url = `sendSMSEdoCuenta?idCliente=${idCliente}&celular=${celular}`
    return new Promise<sendEdoCtaSMSResponse>((resolve, reject) => {
        axiosInstances.apiV2Instance?.get(url)
            .then(response => { resolve(response.data) })
            .catch(error => { reject(error) })
    })
}

export const getAndroidVersion = (): Promise<AndoidVersion> => {
    const url = 'version/android'
    return new Promise<AndoidVersion>((resolve, reject) => {
        axiosInstances.apiV2InstanceWOSecurity?.get<AndoidVersion>(url)
            .then(response => resolve(response.data))
            .catch(err => reject(err))
    })
}

export const sendSimpleSms = (simpleSmsObj: SimpleSms): Promise<SimpleSmsResponse> => {
    return new Promise<SimpleSmsResponse>((resolve, reject) => {
        axiosInstances.apiV2Instance?.post('sendSimpleSms', simpleSmsObj)
            .then(response => { resolve(response.data) })
            .catch(error => { //console.log("err", error)*/
            reject(error) })
    })
}