import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from "react-router-dom";
import 'moment/locale/es';

import storev2 from './storeV2';
import { setStoreV2 } from './storeV2/store';
import { createTheme } from '@material-ui/core';
import RegisterScreen from './views/registerScreen/RegisterScreen';

const megacableTheme = createTheme({
  palette: {
    primary: {
      main: '#006DCC',
      dark: '#044C9B'
    },
    secondary: {
      main: '#002A4F',
      dark: '#01192B',
    },
  },
  typography: {
    h1: {
      fontFamily: "Sarabun"
    },
    h2: {
      fontFamily: "Sarabun"
    },
    h3: {
      fontFamily: "Sarabun"
    },
    h4: {
      fontFamily: "Sarabun"
    },
    h5: {
      fontFamily: "Sarabun"
    },
    h6: {
      fontFamily: "Sarabun",
    },
    body1: {
      fontFamily: "Sarabun",
    },
    body2: {
      fontFamily: "Sarabun",
    },
    button: {
      fontFamily: "Sarabun"
    },
    subtitle1: {
      fontFamily: "Sarabun"
    },
    subtitle2: {
      fontFamily: "Sarabun"
    },
    caption: {
      fontFamily: "Sarabun"
    },
    overline: {
      fontFamily: "Sarabun"
    }
  }
});

function App() {

  setStoreV2(storev2)
  return (
    <ThemeProvider theme={megacableTheme}>
      <div className="App" style={{ height: '100%' }}>
        <Provider store={storev2}>
          <BrowserRouter>
            <Switch>
                <RegisterScreen />
            </Switch>
          </BrowserRouter>
        </Provider>
      </div>
    </ThemeProvider>
  );
}

export default App;
