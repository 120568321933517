// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2019-08-14T16:39:59.971Z

const awsmobile = {
    aws_cloud_logic_custom: [
        {
            // endpoint: 'https://dev-m.cloudservice.it.megacable.com.mx',
            // endpoint: 'https://production-m.cloudservice.it.megacable.com.mx',
            endpoint: 'https://sel-m.cloudservice.it.megacable.com.mx',
            // endpoint: 'http://18.209.34.64:3000',
            name: 'ApiGatewayRestApi',
            region: 'us-east-1',
            auth: undefined
        },
        {
            // endpoint: 'https://dev-m.cloudservice.it.megacable.com.mx',
            // endpoint: 'https://production-m.cloudservice.it.megacable.com.mx',
            endpoint: 'https://sel-m.cloudservice.it.megacable.com.mx',
            // endpoint: 'http://18.209.34.64:3000',
            name: 'MegacableGateway',
            region: 'us-east-1',
            auth: undefined
        },
        {
            endpoint: 'dev-mns.cloudservice.it.megacable.com.mx',
            // endpoint: 'https://jp1vzu2yih.execute-api.us-east-1.amazonaws.com/production',
            // endpoint: 'https://production-mns.cloudservice.it.megacable.com.mx',
            // endpoint: 'https://sel-mns.cloudservice.it.megacable.com.mx',
            name: 'MegacableNotifications',
            region: 'us-east-1',
            auth: 'k@hD62n!vh?V=uezHd@gwJq%kcBS4X@4'
        }
    ],
    aws_project_region: 'us-east-1'
};

export default awsmobile;