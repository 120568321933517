

import React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';


const styles = () => createStyles({
    container: {
        width: "100%",
        display: 'flex',
        justifyContent: 'center',
        padding: "10%",
    },
    spinner: {
        width: 90,
        height: 90,
    }
});

interface SpinnerProps extends WithStyles<typeof styles> {
    
}


export const Spinner = ({ classes }: SpinnerProps) => {

    return (
        <div className={classes.container}>
            <img  src={'/spinner-progress.svg'} alt={"Loading..."} className={classes.spinner}/>
        </div>
    )
}

export default withStyles(styles)(Spinner);


